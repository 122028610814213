import { useAsyncCallback, useCrash } from '@orus.eu/pharaoh'
import { useNavigate, useParams } from '@tanstack/react-router'
import { memo, useEffect } from 'react'
import { trpc, trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { UserPageContent } from '../common/backoffice-users/user-page-content'

const PartnerUserRoutingPage = memo(function PartnerUserRoutingPage() {
  const { userId, organization } = useParams({ from: '/partner/$organization/users/$userId' })
  return <PartnerUserPage userId={userId} organization={organization} />
})

export default PartnerUserRoutingPage

const PartnerUserPage = memo<{ userId: string; organization: string }>(function PlatformUserPage({
  userId,
  organization,
}) {
  const navigate = useNavigate()
  const crash = useCrash()

  assert(userId, 'userId is required')
  assert(organization, 'Organization is required')

  const { isLoading: isUserPartOfOrganizationLoading, data: isUserPartOfOrganization } =
    trpcReact.users.isUserPartOfOrganization.useQuery({ userId, organization })

  const { isLoading, data: user } = trpcReact.usersActivity.loadUserActivityDataByRequestingOrganization.useQuery(
    { userId, requestingOrganization: organization },
    { enabled: isUserPartOfOrganization !== undefined },
  )

  useEffect(() => {
    if (isUserPartOfOrganization === false) {
      crash({ type: 'not-found' })
    }
  }, [crash, isUserPartOfOrganization])

  const customerId = user?.id
  const createSubscription = useAsyncCallback(async () => {
    if (!customerId) return
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({
      customerId,
      organizationShortName: organization,
    })
    void navigate({
      to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
      params: { subscriptionId, organization },
    })
  }, [customerId, navigate, organization])

  if (isLoading || isUserPartOfOrganizationLoading) {
    return <GlobalLoadingState />
  }

  return <UserPageContent type="partner" user={user} customerId={customerId} createSubscription={createSubscription} />
})
